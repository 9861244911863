export function setCache(key: string, value: string | [] | {}, sec: number = 31556926) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        data: value,
        expiry: now.getTime() + (sec * 1000)
    };
    return localStorage.setItem(key, JSON.stringify(item));
}

export function getCache(key: string): (any[] | null) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.data;
}

export function deleteCache(key: string) {
    return localStorage.removeItem(key);
}

export function sizeCache(): string {
    let allStrings = '';
    for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
            allStrings += window.localStorage[key];
        }
    }
    return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)';
}
